// mixin.js
import { mapState } from 'vuex'
export const acl = {   
  computed: {
    ...mapState('auth', ['permissions']),
  },
  methods: {            
    can(action){
      return this.permissions.includes(action)
    },
  }
}  