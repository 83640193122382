<template>
  <div>
    <ConceptsNav/>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions,mapState,mapMutations  } from "vuex";
import ConceptsNav from '@/modules/fivesClub/components/profit/performanceConcepts/ConceptsNav'
export default {
  async mounted() {
    if( this.rentalPoolFilter.length !=0 ) return false
    let rentalPool= await this.fetchGetRentalPoolFilter()
    this.setRentalPoolFilter(rentalPool)
  },
  components:{ ConceptsNav },
  computed:{
    ...mapState("fivesClubProfit", ['rentalPoolFilter']),
  },
  methods: {
    ...mapMutations('fivesClubProfit', ['setRentalPoolFilter']),
    ...mapActions('fivesClubProfit', ["fetchGetRentalPoolFilter"]),
  },
};
</script>
